<template>
  <v-checkbox
    v-model="control.model[control.name]"
    v-bind="control"
    class="jexy-checkbox ml-2"
  />
</template>

<script>

// Mixin
import Control from './Mixin'

export default {
  name: 'Checkbox',
  mixins: [ Control ]
}
</script>
